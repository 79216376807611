'use strict';
angular.module("ngLocale", [], ["$provide", function($provide) {
  var PLURAL_CATEGORY = {ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other"};
  function getDecimals(n) {
    n = n + '';
    var i = n.indexOf('.');
    return (i == -1) ? 0 : n.length - i - 1;
  }

  function getVF(n, opt_precision) {
    var v = opt_precision;

    if (undefined === v) {
      v = Math.min(getDecimals(n), 3);
    }

    var base = Math.pow(10, v);
    var f = ((n * base) | 0) % base;
    return {v: v, f: f};
  }

  $provide.value("$locale", {
    "DATETIME_FORMATS": {
      "AMPMS": [
        "AM",
        "PM"
      ],
      "DAY": [
        "\u0432\u043e\u0441\u043a\u0440\u0435\u0441\u0435\u043d\u044c\u0435",
        "\u043f\u043e\u043d\u0435\u0434\u0435\u043b\u044c\u043d\u0438\u043a",
        "\u0432\u0442\u043e\u0440\u043d\u0438\u043a",
        "\u0441\u0440\u0435\u0434\u0430",
        "\u0447\u0435\u0442\u0432\u0435\u0440\u0433",
        "\u043f\u044f\u0442\u043d\u0438\u0446\u0430",
        "\u0441\u0443\u0431\u0431\u043e\u0442\u0430"
      ],
      "ERANAMES": [
        "\u0434\u043e \u043d. \u044d.",
        "\u043d. \u044d."
      ],
      "ERAS": [
        "\u0434\u043e \u043d. \u044d.",
        "\u043d. \u044d."
      ],
      "FIRSTDAYOFWEEK": 0,
      "MONTH": [
        "\u044f\u043d\u0432\u0430\u0440\u044c",
        "\u0444\u0435\u0432\u0440\u0430\u043b\u044c",
        "\u043c\u0430\u0440\u0442",
        "\u0430\u043f\u0440\u0435\u043b\u044c",
        "\u043c\u0430\u0439",
        "\u0438\u044e\u043d\u044c",
        "\u0438\u044e\u043b\u044c",
        "\u0430\u0432\u0433\u0443\u0441\u0442",
        "\u0441\u0435\u043d\u0442\u044f\u0431\u0440\u044c",
        "\u043e\u043a\u0442\u044f\u0431\u0440\u044c",
        "\u043d\u043e\u044f\u0431\u0440\u044c",
        "\u0434\u0435\u043a\u0430\u0431\u0440\u044c"
      ],
      "SHORTDAY": [
        "\u0432\u0441",
        "\u043f\u043d",
        "\u0432\u0442",
        "\u0441\u0440",
        "\u0447\u0442",
        "\u043f\u0442",
        "\u0441\u0431"
      ],
      "SHORTMONTH": [
        "\u044f\u043d\u0432.",
        "\u0444\u0435\u0432\u0440.",
        "\u043c\u0430\u0440\u0442",
        "\u0430\u043f\u0440.",
        "\u043c\u0430\u0439",
        "\u0438\u044e\u043d\u044c",
        "\u0438\u044e\u043b\u044c",
        "\u0430\u0432\u0433.",
        "\u0441\u0435\u043d\u0442.",
        "\u043e\u043a\u0442.",
        "\u043d\u043e\u044f\u0431.",
        "\u0434\u0435\u043a."
      ],
      "WEEKENDRANGE": [
        5,
        6
      ],
      "fullDate": "EEEE, d MMMM y '\u0433'.",
      "longDate": "d MMMM y '\u0433'.",
      "medium": "d MMM y '\u0433'. H:mm:ss",
      "mediumDate": "d MMM y '\u0433'.",
      "mediumTime": "H:mm:ss",
      "short": "dd.MM.yy H:mm",
      "shortDate": "dd.MM.yy",
      "shortTime": "H:mm"
    },
    "NUMBER_FORMATS": {
      "CURRENCY_SYM": "\u0440\u0443\u0431.",
      "DECIMAL_SEP": ",",
      "GROUP_SEP": "\u00a0",
      "PATTERNS": [
        {
          "gSize": 3,
          "lgSize": 3,
          "maxFrac": 3,
          "minFrac": 0,
          "minInt": 1,
          "negPre": "-",
          "negSuf": "",
          "posPre": "",
          "posSuf": ""
        },
        {
          "gSize": 3,
          "lgSize": 3,
          "maxFrac": 2,
          "minFrac": 2,
          "minInt": 1,
          "negPre": "-",
          "negSuf": "\u00a0\u00a4",
          "posPre": "",
          "posSuf": "\u00a0\u00a4"
        }
      ]
    },
    "id": "ru",
    "pluralCat": function(n, opt_precision) {  var i = n | 0;  var vf = getVF(n, opt_precision);  if (vf.v == 0 && i % 10 == 1 && i % 100 != 11) {    return PLURAL_CATEGORY.ONE;  }  if (vf.v == 0 && i % 10 >= 2 && i % 10 <= 4 && (i % 100 < 12 || i % 100 > 14)) {    return PLURAL_CATEGORY.FEW;  }  if (vf.v == 0 && i % 10 == 0 || vf.v == 0 && i % 10 >= 5 && i % 10 <= 9 || vf.v == 0 && i % 100 >= 11 && i % 100 <= 14) {    return PLURAL_CATEGORY.MANY;  }  return PLURAL_CATEGORY.OTHER;}
  });
}]);
